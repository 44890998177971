import RPCompetency from "./competency";

const templates = [
  {
    id: "competency",
    label: "Competency",
    template: RPCompetency
  }
];

export default templates;
