import RPTranscript from "./transcript";

const templates = [
  {
    id: "transcript",
    label: "Transcript",
    template: RPTranscript
  }
];

export default templates;
